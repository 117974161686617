<template>
  <app-layout>
    <div class="flex flex-col items-center pb-32">
      <div class="w-full px-6 pt-6 2xl:w-3/5 2xl:px-0">
        <div class="w-full mb-4 space-y-4">
          <div class="flex flex-col md:flex-row py-4">
            <div class="flex flex-row items-center">
              <sa-icon-button
                with-bg
                iconClass="bx bx-left-arrow-alt"
                @click="$router.push({ name: 'orders.read', params: { id: order.id } })" />
              <span class="mx-2 text-lg font-bold">
              Factura
            </span>
            </div>
            <div class="flex flex-row items-center ml-auto">
              <el-button @click="goPoliciesAndTerms" class="terms-button" :class="{ 'p-2' : isMobile }">
                <span v-if="!isMobile">Configuración</span>
                <i v-if="isMobile" class='text-md bx bx-cog'></i>
              </el-button>
              <div class="whitespace-nowrap">
                <el-dropdown v-if="id && !isMobile" split-button type="warning" class="mx-2" @click="print" @command="print">
                  <span>Imprimir</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="default">Carta</el-dropdown-item>
                    <el-dropdown-item disabled>Térmico</el-dropdown-item>
                    <el-dropdown-item command="sticker">Sticker</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button-group v-if="id && isMobile" class="mr-2" style="margin-top: -6px;">
                  <span class="el-button el-button--warning p-2" @click="print">
                    <i class="text-md bx bx-printer"></i>
                  </span>
                  <el-dropdown trigger="click" @command="print">
                    <span class="el-button el-button--warning p-2">
                      <i class="text-md el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="default">Carta</el-dropdown-item>
                      <el-dropdown-item disabled>Térmico</el-dropdown-item>
                      <el-dropdown-item command="sticker">Sticker</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-button-group>
                <el-button @click="whatsapp" class="el-button--success" :class="{ 'p-2' : isMobile }" v-if="id">
                  <span v-if="!isMobile">WhatsApp</span>
                  <i v-if="isMobile" class='text-md bx bxl-whatsapp'></i>
                </el-button>
                <el-button @click="save" class="el-button--primary" :class="{ 'p-2' : isMobile }">
                  <span v-if="!isMobile">Guardar</span>
                  <i v-if="isMobile" class='text-md bx bx-save'></i>
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div id="print-area" class="w-full lg:w-1/2 items-center text-center m-auto space-y-4 relative">
          <div class="w-full items-center text-center py-2">
            <div class="w-full flex flex-col" style="align-items: center;">
              <div>
                <div v-if="showLogo && logo" style="text-align: -webkit-center;">
                  <img :src="logo" alt="" :style="samiiInvoicesLogoSize" />
                </div>
                <div v-if="showLogo && !logo" style="text-align: -webkit-center;">
                  <img src="@/assets/img/mini-responsive-samii.png" alt="" width="70px">
                </div>
                <div v-if="showBusinessName">
                  <span class="font-bold text-3xl font-headline">
                    {{ $store.state.business.name || 'Fixme up' }}
                  </span>
                </div>
              </div>
              <span class="font-bold text-lg">
              {{ $store.state.business.address }}
            </span>
            <span class="font-bold text-lg">
              {{ phone }}
            </span>
            </div>
          </div>
          <div class="-mx-5 border-t border-gray-200" />
          <div class="w-full items-center text-center py-2">
            <div class="w-full flex flex-col border rounded-sm text-left p-2">
              <div class="text-md">
                <strong>Fecha de Emisión: </strong>
                {{ todayDate }}
              </div>
              <div class="text-md">
                <strong>Cliente: </strong>
                {{ order.client.fullName }}
              </div>
              <div class="text-md" v-if="order.client.dni">
                <strong>Numero de Identificación: </strong>
                {{ order.client.dni }}
              </div>
              <div class="text-md" v-if="order.client.contactDescription">
                <strong>Dirección: </strong>
                {{ order.client.contactDescription }}
              </div>
              <div class="text-md flex flex-col">
                <strong>Contacto Cliente: </strong>
                <span class="pl-4" style="font-size: 14px;">
                  <strong>- Teléfono: </strong>
                  {{ order.client.phone }}
                </span>
                <span class="pl-4" style="font-size: 14px;" v-if="order.client.email">
                  <strong>- Correo: </strong>
                  {{ order.client.email }}
                </span>
              </div>
              <div class="text-md" v-if="assigned">
                <strong>Asignado: </strong>
                {{ assigned }}
              </div>
              <div class="text-md" v-if="dueDate">
                <strong>Fecha Compromiso: </strong>
                {{ dueDate }}
              </div>
            </div>
          </div>
          <div class="-mx-5 border-t border-gray-200" />
          <div class="w-full items-center text-center py-2">
            <div class="w-full text-xl border bill-container rounded-sm">{{ order.reference }}</div>
          </div>
          <div class="-mx-5 border-t border-gray-200" />
          <div v-if="order.orderDevices?.length" class="w-full items-center text-center py-2">
            <div class="w-full">
              <div class="border bill-container">DATOS DE EQUIPO</div>
              <div class="border block w-full overflow-x-auto">
                <div class="w-full flex flex-row border-b">
                  <div
                    class="w-1/12 font-bold text-left px-2">
                    #
                  </div>
                  <div
                    class="w-5/12 font-bold text-left px-2">
                    Modelo
                  </div>
                  <div
                    class="w-3/12 font-bold text-right px-2">
                    Cantidad
                  </div>
                  <div
                    class="w-3/12 font-bold text-right px-2">
                    Precio
                  </div>
                </div>
                <div
                  class="w-full flex flex-row border-b"
                  v-for="(device, index) in order.orderDevices"
                  :key="device.id">
                  <div class="w-1/12 text-left px-2">
                    {{ index + 1 }}-
                  </div>
                  <div class="w-11/12 text-left px-2">
                    <div>{{ `${device.deviceModel.brand} - ${device.deviceModel.model}` }}</div>
                    <div v-if="!device.serialNumberType || device.serialNumberType === 2">
                      IMEI: {{ device.imei }}
                    </div>
                    <div v-if="device.serialNumberType === 1">
                      SN: {{ device.imei }}
                    </div>
                    <div v-if="showPasscode && device.deviceLockType === 1">
                      PASSCODE: {{ device.passcode }}
                    </div>
                    <div v-if="showPasscode && device.deviceLockType === 2">
                      PATRON: {{ device.pattern }}
                    </div>
                    <div class="border-t py-2">
                      <div
                        class="w-full flex flex-col border-b"
                        v-for="breakdown in breakdownResume[device.id]"
                        :key="breakdown.id">
                        <div class="w-full flex flex-row">
                          <div class="w-1/12 text-left px-2" style="word-break: break-word;" />
                          <div class="w-5/12 text-left px-2" style="word-break: break-word;">
                            - {{ breakdown.name }}
                          </div>
                          <div class="w-3/12 text-right px-2" style="word-break: break-word;">
                            {{ breakdown.quantity }}
                          </div>
                          <div class="w-3/12 text-right px-2" style="word-break: break-word;">
                            {{ moneyFormat(breakdown.price) }}
                          </div>
                        </div>
                      </div>
                      <div class="w-full" v-if="device.hasWarranty">
                        Garantía: {{ `${device.warrantyDays} ${device.warrantyDays === 1 ? 'Dia' : 'Dias'}` }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="-mx-5 border-t border-gray-200" />
          <div v-if="order.orderProducts?.length" class="w-full items-center text-center py-2">
            <div class="w-full">
              <div class="border bill-container">VENTAS</div>
              <div class="border block w-full overflow-x-auto">
                <div class="w-full flex flex-row border-b">
                  <div
                    class="w-1/12 font-bold text-left px-2">
                    #
                  </div>
                  <div
                    class="w-5/12 font-bold text-left px-2">
                    Producto
                  </div>
                  <div
                    class="w-3/12 font-bold text-left px-2">
                    Cantidad
                  </div>
                  <div
                    class="w-3/12 font-bold text-right px-2">
                    Precio
                  </div>
                </div>
                <div
                  class="w-full flex flex-row border-b"
                  v-for="(product, index) in order.orderProducts"
                  :key="product.id">
                  <div class="w-1/12 text-left px-2">
                    {{ index + 1 }}-
                  </div>
                  <div class="w-5/12 text-left px-2">
                    {{ product.name }}
                  </div>
                  <div class="w-3/12 text-left px-2">
                    {{ product.quantity || 1 }}
                  </div>
                  <div class="w-3/12 text-right px-2">
                    {{ moneyFormat(product.price * (product.quantity || 1)) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="-mx-5 border-t border-gray-200" />
          <div class="w-full items-center text-center py-2">
            <div class="w-full">
              <div class="border bill-container">PRESUPUESTO</div>
              <div class="border block w-full overflow-x-auto">
                <div class="w-full flex flex-row border-b">
                  <div class="w-4/12 font-bold text-left px-2 border-r">
                    Total
                  </div>
                  <div class="w-4/12 font-bold text-left px-2 border-r">
                    Anticipo
                  </div>
                  <div class="w-4/12 font-bold text-left px-2">
                    Adeudo
                  </div>
                </div>
                <div class="w-full flex flex-row border-b">
                  <div class="w-4/12 text-right px-2 border-r">
                    {{ moneyFormat(resume.total) }}
                  </div>
                  <div class="w-4/12 text-right px-2 border-r">
                    {{ moneyFormat(resume.advance) }}
                  </div>
                  <div class="w-4/12 text-right px-2">
                    {{ moneyFormat(resume.debit) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="-mx-5 border-t border-gray-200" />
          <div class="w-full items-center text-center py-2">
            <div class="w-full py-2 text-center" style="text-align: -webkit-center;">
              <div
                v-if="!signature"
                @click="signInvoice"
                class="w-36 h-16 border border-black flex items-center justify-center cursor-pointer">
                <span class="text-gray-400 text-lg">Firma</span>
              </div>
              <img v-if="signature" :src="signature" alt="" class="border" style="height: 200px !important;" />
              <div>Nombre y Firma</div>
              <div>Cliente</div>
            </div>
          </div>
          <div class="-m-5 border-t border-gray-200" />
          <div class="w-full items-center text-center py-2">
            <div class="w-full text-center" style="text-align: -webkit-center;">
              <qr-code
                class="pb-2"
                :value="qrCodeValue"
                size="120"
                level="H" />
              <strong>Consulta el estado de tu servicio <br /> escaneando el QR</strong>
            </div>
          </div>
          <div class="-m-5 border-t border-gray-200" />
          <div class="w-full items-center text-center py-2">
            <div class="border bill-container">GARANTÍA</div>
            <div class="border text-left">
              <policy />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="showSignature">
      <signature
        id="default-invoice"
        @signature:update="onSignatureUpdate"
      ></signature>
    </el-dialog>
  </app-layout>
</template>
<style scoped>
.bill-container {
  background-color: #000000;
  border-color: #000000;
  color: #FFFFFF;
  outline: none;
}
#print > span, div, strong {
  color: #000000;
}
@media print {
  body *:not(#print-area):not(#print-area *) {
    visibility: hidden;
  }
  #print-area {
    position: absolute;
    visibility: visible;
    top: 0;
    left: 16px;
    width: 3.9in;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  #print-area * {
    overflow-x: hidden;
  }
}
.terms-button {
  @apply bg-blue-500 text-white;
}

.terms-button:hover {
  @apply opacity-80;
}
</style>
<script>
import FileService from "@/services/FileService";
import AdminService from '@/services/AdminService';
import OrderService from '@/services/OrderService';
import numbers from '@/utils/numbers';
import mappers from './mappers';
import { Whatsapp, getCountryCode } from '@/utils/phones';
import { tag, FileType } from '@/constants';
import BusinessGetters from '@/store/modules/business/getters';
import responsiveSize from '@/mixins/responsiveSize';
import { PrintTypes } from '@/constants';
import { dataURLtoFile } from '@/utils/files';

export default {
  name: 'InvoiceThermal',
  mixins: [responsiveSize],
  components: {
    AppLayout: () => import('@/components/layouts/AppLayout'),
    Policy: () => import('@/components/molecules/Policy'),
    Signature: () => import('@/components/dialogs/Signature'),
  },
  data() {
    return {
      id: null,
      order: {
        reference: null,
        client: {
          fullName: null,
          phone: null,
          email: null,
          contactDescription: null,
          dni: null,
        },
        assigned: null,
        dueDate: null,
        orderDevices: [],
        advanceAmount: 0,
        subtotalAmount: 0,
        taxAmount: 0,
        orderAdvances: [],
        orderProducts: [],
      },
      breakdownResume: {},
      policy: null,
      isMobile: false,
      showSignature: false,
      signature: null,
    }
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  mounted() {
    this.id = this.$route.params.id;
    this.load();
    this.loadPolicy();
  },
  computed: {
    phone() {
      const { phone, phoneCountryCodeId } = this.$store.state.business;
      const flag = this.$store.state.catalogs.flags.find((f) => f.id === phoneCountryCodeId);
      if (flag) {
        return `+${flag.countryCode} ${phone}`
      }
      return phone;
    },
    todayDate() {
      const format = `${this.$store.state.business.dateFormat?.toUpperCase()} hh:mm:ss A` ?? "MM/DD/YYYY hh:mm:ss A";
      return this.$moment(new Date()).format(format);
    },
    assigned() {
      if (this.order.assignedToAccount)
        return this.order.assignedToAccount.fullname;
      return null;
    },
    dueDate() {
      if (this.order.dueDate) {
        const format = this.$store.state.business.dateFormat?.toUpperCase() ?? "MM/DD/YYYY";
        return this.$moment(this.order.dueDate).format(format);
      }
      return null;
    },
    resume() {
      const totalAmount = this.order.subtotalAmount + this.order.taxAmount;
      const advanceAmount = this.order.orderAdvances.reduce((acc, curr) => acc -= curr.advanceAmount, 0);

      return {
        total: totalAmount,
        advance: advanceAmount,
        debit: totalAmount + advanceAmount,
      }
    },
    qrCodeValue() {
      return `${window.location.origin}/my-order-status/${this.id}`;
    },
    showLogo() {
      const config = this.$store.state.invoices.config[PrintTypes.Thermal];
      if (!config) {
        return true;
      }
      return config.showLogo;
    },
    showBusinessName() {
      const config = this.$store.state.invoices.config[PrintTypes.Thermal];
      if (!config) {
        return true;
      }
      return config.showBusinessName;
    },
    showPasscode() {
      const config = this.$store.state.invoices.config[PrintTypes.Thermal];
      if (!config) {
        return true;
      }
      return config.showPasscode;
    },
    logo() {
      return BusinessGetters.logo();
    },
    samiiInvoicesLogoSize() {
      const config = this.$store.state.invoices.config[PrintTypes.Thermal];
      if (!config) {
        return {
          width: `100px !important`,
          height: `100px !important`,
        }
      }
      return {
        width: `${config.logoX}px !important`,
        height: `${config.logoY}px !important`,
      }
    },
  },
  methods: {
    async signInvoice() {
      this.showSignature = true;
    },
    onSignatureUpdate(signature) {
      this.signature = signature;
      this.showSignature = false;
    },
    whatsapp() {
      const countryCode = getCountryCode(
        this.$store.state.catalogs.flags,
        this.order.client.phoneCountryCodeId,
      );
      if(!countryCode){
        this.$toastr.e('El Cliente debe tener registrado el Código de Area');
        return;
      }
      Whatsapp({
        code: countryCode,
        phone: this.order.client.phone,
        text: `Hola te envío el comprobante de tu orden.${tag.br}${tag.br}*${this.qrCodeValue}*`,
      });
      OrderService.addHistoryWhatsapp({
        orderId: this.order.id,
        message: `Hola te envío el comprobante de tu orden. *${this.qrCodeValue}*`,
      });
    },
    print(command) {
      if (typeof command === "string") {
        this.$router.push({ name: `invoices.${command}`, params: { id: this.id } });
      } else {
        window.print();
      }
    },
    async load() {
      const result = await OrderService.getById(this.id);
      this.order = mappers.mapGetResponse(result);
      this.signature  = this.order.signature;
      this.breakdownResume = mappers.mapGetBreakdownResume(this.order.orderDevices);
    },
    async loadPolicy() {
      const { policy } = await AdminService.PoliciesAndTemplates.read();
      if (policy) this.policy = policy;
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.isMobile = percentageWidth === '90%';
    },
    goPoliciesAndTerms() {
      const isOwner = this.$store.state.account.accountType === 1;
      const { canViewAdministration } = this.$store.state.account;
      if(isOwner || canViewAdministration){
        this.$router.push({ name: 'admin.index', params: { slot: 'policies-and-templates' } });
        return;
      }
      this.$swal('',
        'No tienes el permiso para realizar esta acción ',
        'error')
    },
    async save() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append('File', dataURLtoFile(this.signature));
        formData.append('Type', FileType.OrderSignature);
        const signature = await FileService.create(formData);
        await OrderService.updateOrderSignature(this.id, signature);
        this.$confirm(`¿Quieres ir al listado de ordenes?`, {
          type: 'warning',
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancelar',
        }).then(() => {
          this.$router.push({ name: 'orders.index' });
        });
      } catch (e) {
        this.$router.push({ name: 'orders.index' });
      } finally {
        this.loading = false;
      }
    }
  },
}
</script>
